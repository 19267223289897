
<template>
  <div class="map">
      <div class="group-1 row">
        <!-- Starting Sidebar Button -->
        <div class="filter-button col-2">
          <b-button
            v-b-tooltip.hover.left="'Filter Peta'"
            v-b-toggle.my-sidebar
            variant="primary"
            ><feather-icon icon="MenuIcon"
          /></b-button>
        </div>
        <!-- Ending Sidebar Button -->
      </div>

      <!-- legend -->
      <b-sidebar id="my-sidebar" title="" right backdrop shadow>
        <div class="px-3 py-2">
          <h4 class="mb-3">Filter Infrastruktur</h4>
          <multiselect
            v-model="dataValue"
            :options="dataOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :group-select="false"
            placeholder="Pilih Infrastruktur"
            label="name"
            track-by="name"
            group-values="libs"
            group-label="language"
            @select="dataSelected"
            @remove="dataDeselect"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span
                v-if="values.length &amp;&amp; !isOpen"
                class="multiselect__single"
                >{{ values.length }} options selected</span
              >
            </template>
          </multiselect>

          <multiselect
            v-model="vmSearch"
            :options="optSearch"
            :custom-label="cstmSearch"
            placeholder="Semua Provinsi"
            label="nama"
            track-by="id"
            select-label=" "
            deselect-label=" "
            @input="provSelect"
            @remove="provDeselect"
            class="mt-1"
          />

          <multiselect
            v-model="infraSearch"
            :options="optionInfra"
            :allow-empty="true"
            :multiple="false"
            group-values="libs"
            group-label="language"
            :group-select="false"
            placeholder="Cari Infastruktur"
            track-by="tooltip"
            label="tooltip"
            tagPlaceholder=""
            @input="selectedInfra"
            @remove="delectedInfra"
            class="mt-1"

          >
            <span slot="noOptions">
              Silahkan Pilih Jenis Infastruktur Dahulu
            </span>
            <span slot="noResult"
              >Oops! No elements found. Consider changing the search
              query.</span
            >
          </multiselect>

          <hr class="my-4" />

          <h4 class="mb-2">Jenis Peta</h4>
          <b-form-radio
            v-model="map_type"
            name="jenis-peta"
            value="open"
            class="mb-2"
            @change="mapChange"
            >OpenStreetMap</b-form-radio
          >
          <b-form-radio
            v-model="map_type"
            name="jenis-peta"
            value="topo"
            class="mb-2"
            @change="mapChange"
            >OpenTopoMap</b-form-radio
          >
          <hr class="my-4" />

          <h4 class="mb-3">Filter Peta</h4>
          <b-form-checkbox
            id="peta-cat"
            v-model="petaCat.visible"
            name="peta-cat"
            :value="true"
          >
            Peta CAT
          </b-form-checkbox>

          <b-form-checkbox
            id="peta-ws"
            v-model="petaWs.visible"
            name="peta-ws"
            :value="true"
          >
            Peta WS
          </b-form-checkbox>

          <b-form-checkbox
            id="peta-hidro"
            v-model="petaHidro.visible"
            name="peta-hidro"
            :value="true"
          >
            Peta Hidrogeologi
          </b-form-checkbox>

          <b-form-checkbox
            id="peta-geo"
            v-model="petaGeo.visible"
            name="peta-geo"
            :value="true"
          >
            Peta Geologi
          </b-form-checkbox>
          <b-form-checkbox
            id="batas-prov"
            v-model="batasProv.visible"
            nama="batas-prov"
            :value="true"
          >
            Batas Provinsi
          </b-form-checkbox>

          <hr class="my-4" />

          <h4 class="mb-3">Legenda</h4>
          <div v-for="data in dataLegends" :key="data.name" class="row">
            <div class="px-2 py-1">
              <div
                v-for="detail in data.detail"
                id="col-body"
                :key="detail.isActive"
                class="row"
              >
                <div class="col-1">
                  <b-img
                    :src="
                      require('@/assets/images/icons/markers/' + detail.icon)
                    "
                    :alt="detail.isActive"
                    width="20px"
                  />
                </div>
                <div class="col-sm">
                  <label> {{ detail.isActive }} </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-sidebar>

      <div v-if="peta_legend.legend_card" id="legend" class="container">
        <div class="row">
          <div id="col-head" class="col head">
            <b>Legenda</b>
            <hr />
          </div>
        </div>
        <div v-for="data in dataLegends" :key="data.name" class="row">
          <div v-if="data.visible">
            <div
              v-for="detail in data.detail"
              id="col-body"
              :key="detail.isActive"
              class="row"
            >
              <div class="col-1">
                <b-img
                  :src="require('@/assets/images/icons/markers/' + detail.icon)"
                  :alt="detail.isActive"
                  width="20px"
                />
              </div>
              <div class="col-sm">
                <label> {{ detail.isActive }} </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pencarian berdasarkan wilayah -->
      <div id="map_tes">
        <l-map
          ref="myMap"
          :zoom.sync="zoom"
          :options="mapOptions"
          :center="center"
          :bounds="bounds"
          :min-zoom="minZoom"
          :max-zoom="maxZoom"
          @update:zoom="zoomUpdated"
          style="width: 100%; position: absolute; top: 0; bottom: 0"
        >
          <div>
            <l-tile-layer
              :visible="tileProviders[0].visible"
              :url="tileProviders[0].url"
              :attribution="tileProviders[0].attribution"
            />
            <l-tile-layer
              :visible="tileProviders[1].visible"
              :url="tileProviders[1].url"
              :attribution="tileProviders[1].attribution"
            />
          </div>
          <!-- memilih peta -->

          <div v-if="petaCat.visible">
            <l-geo-json
              :geojson="geojsonCat"
              :options="optionsGeojsonCat"
              :options-style="styleGeojsonCat"
            />
          </div>
          <div v-if="petaWs.visible">
            <l-geo-json
              :geojson="geojsonWs"
              :options="optionsGeojsonWs"
              :options-style="styleGeojsonWs"
            />
          </div>
          <div v-if="petaHidro.visible">
            <l-geo-json
              :geojson="geojsonHidro"
              :options="optionsGeojsonHidro"
              :options-style="styleGeojsonHidro"
            />
          </div>
          <div v-if="petaGeo.visible">
            <l-geo-json
              :geojson="geojsonGeo"
              :options="optionsGeojsonGeo"
              :options-style="styleGeojsonGeo"
            />
          </div>
          <div v-if="batasProv.visible">
            <l-geo-json
              :geojson="geojsonProv"
              :options="optionsGeojsonProv"
              :options-style="styleGeojsonProv"
            />
          </div>

          <l-control-zoom :position="zoomPosition" :visible="zoomVisible" />
          <l-control-attribution
            :position="attributionPosition"
            :prefix="attributionPrefix"
          />

          <!-- starting marker cluster  -->
          <!-- <v-marker-cluster> -->
          <!-- marker sungai -->
          <l-marker
            v-for="marker in markerSungai"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng.sync="marker.position"
            :icon="marker.active ? iconSungai : iconSungaiNonActive"
            @click="alert(marker)"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <!-- marker penampungan air hujan -->
          <l-marker
            v-for="marker in markerPenampunganAirHujan"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng.sync="marker.position"
            :icon="
              marker.active
                ? iconPenampunganAirHujan
                : iconPenampunganAirHujanNonActive
            "
            @click="alert(marker)"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <!-- marker tampungan -->
          <l-marker
            v-for="marker in markerTampungan"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng.sync="marker.position"
            :icon="marker.active ? iconTampungan : iconTampunganNonActive"
            @click="alert(marker)"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <!-- marker mata air -->
          <l-marker
            v-for="marker in markerMataAir"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng.sync="marker.position"
            :icon="marker.active ? iconMataAir : iconMataAirNonActive"
            @click="alert(marker)"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <!-- marker sumur -->
          <l-marker
            v-for="marker in markerSumur"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng.sync="marker.position"
            :icon="marker.active ? iconSumur : iconSumurNonActive"
            @click="alert(marker)"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <!-- </v-marker-cluster> -->
          <!-- ending marker cluster -->
        </l-map>
      </div>
      <b-modal
        ref="modal-scrollable"
        centered
        scrollable
        hide-footer
        size="lg"
        :title="modalTitle"
        cancel-variant="outline-secondary"
      >
        <div v-if="modalLoading" class="text-center">
          <b-spinner variant="primary" type="grow" label="Spinning" />
        </div>
        <div v-else>
          <b-tabs content-class="pt-1" fill>
            <b-tab active title="Informasi Dasar">
              <b-table stacked :items="modalData.infoDasar" />
            </b-tab>
            <b-tab title="Lokasi">
              <b-table stacked :items="modalData.lokasi" />
              <div class="inset-map">
                <LMap
                  v-if="insetMapShowMap"
                  :zoom="insetMapZoom"
                  :center="insetMapCenter"
                  :options="insetMapMapOptions"
                  :min-zoom="insetMapMinZoom"
                  :max-zoom="insetMapMaxZoom"
                  :bounds="insetMapBounds"
                >
                  <LTileLayer
                    :url="insetMapUrl"
                    :attribution="insetMapAttribution"
                  />
                  <LMarker :lat-lng.sync="insetMapMarker" :icon="iconDefault">
                    <LTooltip>{{ modalTitle }}</LTooltip>
                  </LMarker>
                </LMap>
              </div>
            </b-tab>
            <b-tab title="Photo">
              <div v-if="modalData.images.length > 0">
                <swiper class="swiper-paginations" :options="swiperOptions">
                  <swiper-slide
                    v-for="(data, index) in modalData.images"
                    :key="index"
                  >
                    <b-img :src="data.img" fluid />
                  </swiper-slide>

                  <div slot="pagination" class="swiper-pagination" />
                  <div slot="button-next" class="swiper-button-next" />
                  <div slot="button-prev" class="swiper-button-prev" />
                </swiper>
              </div>
              <div v-else>
                <b-alert variant="danger" show>
                  <div class="alert-body">
                    <feather-icon icon="InfoIcon" class="mr-50" />
                    Belum ada photo.
                  </div>
                </b-alert>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <div id="imgCon" class="imgCon"></div>
  </div>
</template>
<style>
:root {
  --geo-json-label-size: 2px;
}
</style>
<script>
/* eslint-disable global-require */

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
  BModal,
  VBModal,
  BTabs,
  BTab,
  BTable,
  BSpinner,
  BImg,
  BAlert,
  BSidebar,
  VBToggle,
  VBTooltip,
  BFormCheckbox,
  // BFormCheckboxGroup,
  // BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  // BFormGroup
} from "bootstrap-vue";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPopup,
  LControl,
  LControlZoom,
  LControlAttribution,
  // LControlLayers,
  // LLayerGroup,
  LGeoJson,
} from "vue2-leaflet";
import L, { latLng, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import Multiselect from "vue-multiselect";
import axiosIns from "@/libs/axios";
import * as geolib from "geolib";
// import petaCat from '@/assets/data/cat_indo.json'
// import petaWS from '@/assets/data/prancis.json'
// import petaGeo from '@/assets/data/geologi.json'
// import peta_ws from '@/assets/data/ws_128.json'
// import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import "@/assets/css/MarkerCluster.css";
import "@/assets/css/MarkerCluster.Default.css";
import domtoimage from "dom-to-image";
import leafletImage from "leaflet-image";
import "@/assets/module/Leaflet.BigImage.js";
import "@/assets/module/html2canvas.js";
import "@/assets/module/Leaflet.BigImage.css";
import { saveAs } from "file-saver";

export default {
  name: "Map",
  components: {
    LControl,
    BModal,
    BTabs,
    BTab,
    BSidebar,
    BTable,
    BSpinner,
    BImg,
    BAlert,
    Swiper,
    SwiperSlide,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    // LControlLayers,
    Multiselect,
    // LLayerGroup,
    LGeoJson,
    // 'b-form-checkbox-group': BFormCheckboxGroup,
    "b-form-checkbox": BFormCheckbox,
    // 'b-form-radio-group': BFormRadioGroup,
    "b-form-radio": BFormRadio,
    "b-form-select": BFormSelect,
    // 'b-form-group': BFormGroup
    // 'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      show_overlay:false,
      root: null,
      label_size: 2,
      infraSearch: null,
      optionInfra: [],
      selected_search: null,
      options_search: [
        { value: null, text: "Jenis Infrastruktur" },
        { value: "sungai", text: "Intake Sungai" },
        { value: "penampunganAirHujan", text: "Penampungan Air Hujan" },
        { value: "tampungan", text: "Tampungan" },
        { value: "mataAir", text: "Mata Air" },
        { value: "sumur", text: "Sumur" },
      ],
      // Starting Searching Bar
      optSearch: [],
      vmSearch: { id: 0, nama: "Semua Provinsi" },
      provinsi_id: null,
      // Ending Searching Bar
      download_peta: false,
      map_type: "open",
      checkbox_status: false,
      geojsonCat: null,
      geojsonWs: null,
      geojsonHidro: null,
      geojsonGeo: null,
      geojsonProv: null,
      opacity: 0.6,
      token: "your token if using mapbox",
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      center: [-1.056451, 120.364674],
      zoom: 5,
      init_zoom: 5,
      minZoom: 1,
      maxZoom: 24,
      legendPosition: "topright",
      zoomPosition: "topright",
      attributionPosition: "bottomright",
      zoomVisible: false,
      layersPosition: "topleft",
      attributionPrefix:
        "Kementerian Pekerjaan Umum dan Perumahan Rakyat Republik Indonesia",
      peta_legend: {
        legend_card: false,
      },
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
      ],

      petaCat: {
        id: "cat1",
        name: "Peta CAT",
        visible: false,
      },
      petaWs: {
        id: "ws1",
        name: "Peta WS",
        visible: false,
      },

      petaHidro: {
        id: "hidro1",
        name: "Peta Hidrogeologi",
        visible: false,
      },
      petaGeo: {
        id: "geo1",
        name: "Peta Geologi",
        visible: false,
      },
      batasProv: {
        id: "prov1",
        name: "Batas Provinsi",
        visible: false,
      },
      bounds: null,
      boundsSungaiArr: [],
      boundsPenampunganAirHujanArr: [],
      boundsTampunganArr: [],
      boundsMataAirArr: [],
      boundsSumurArr: [],
      markerSungai: [],
      markerPenampunganAirHujan: [],
      markerTampungan: [],
      markerMataAir: [],
      markerSumur: [],
      iconDefault: L.icon({
        iconUrl: require("@/assets/images/icons/markers/marker-icon.png"),
        iconSize: [20, 20],
      }),
      iconSungai: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-01.png"),
        iconSize: [20, 20],
      }),
      iconSungaiNonActive: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-01-nonactive.png"),
        iconSize: [20, 20],
      }),
      iconPenampunganAirHujan: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-02.png"),
        iconSize: [20, 20],
      }),
      iconPenampunganAirHujanNonActive: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-02-nonactive.png"),
        iconSize: [20, 20],
      }),
      iconTampungan: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-03.png"),
        iconSize: [20, 20],
      }),
      iconTampunganNonActive: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-03-nonactive.png"),
        iconSize: [20, 20],
      }),
      iconMataAir: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-04.png"),
        iconSize: [20, 20],
      }),
      iconMataAirNonActive: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-04-nonactive.png"),
        iconSize: [20, 20],
      }),
      iconSumur: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-05.png"),
        iconSize: [20, 20],
      }),
      iconSumurNonActive: L.icon({
        iconUrl: require("@/assets/images/icons/markers/focus-05-nonactive.png"),
        iconSize: [20, 20],
      }),
      dataLegends: [
        {
          name: "sungai",
          visible: false,
          detail: [
            {
              isActive: "Intake Sungai Aktif ",
              icon: "focus-01.png",
            },
            {
              isActive: "Intake Sungai Nonaktif",
              icon: "focus-01-nonactive.png",
            },
          ],
        },
        {
          name: "penampunganAirHujan",
          visible: false,
          detail: [
            {
              isActive: "PAH/ABSAH Aktif",
              icon: "focus-02.png",
            },
            {
              isActive: "PAH/ABSAH Nonaktif",
              icon: "focus-02-nonactive.png",
            },
          ],
        },
        {
          name: "tampungan",
          visible: false,
          detail: [
            {
              isActive: "Tampungan Aktif",
              icon: "focus-03.png",
            },
            {
              isActive: "Tampungan Nonaktif",
              icon: "focus-03-nonactive.png",
            },
          ],
        },
        {
          name: "mataAir",
          visible: false,
          detail: [
            {
              isActive: "Mata Air Aktif",
              icon: "focus-04.png",
            },
            {
              isActive: "Mata Air Nonaktif",
              icon: "focus-04-nonactive.png",
            },
          ],
        },
        {
          name: "sumur",
          visible: false,
          detail: [
            {
              isActive: "Sumur Aktif",
              icon: "focus-05.png",
            },
            {
              isActive: "Sumur Nonaktif",
              icon: "focus-05-nonactive.png",
            },
          ],
        },
      ],
      dataValue: [],
      dataOptions: [
        {
          language: "Air Baku",
          libs: [
            { name: "Intake Sungai", endpoint: "sungai" },
            { name: "Penampungan Air Hujan", endpoint: "penampunganAirHujan" },
            { name: "Tampungan", endpoint: "tampungan" },
          ],
        },
        {
          language: "Air Tanah",
          libs: [
            { name: "Mata Air", endpoint: "mataAir" },
            { name: "Sumur", endpoint: "sumur" },
          ],
        },
      ],
      loading: false,
      selectedWil: null,
      wilayah: null,
      dataOptionsWil: [
        { name: "WS", endpoint: "sungai" },
        { name: "DAS", endpoint: "penampunganAirHujan" },
        { name: "CAT", endpoint: "tampungan" },
      ],
      picked: "CAT",
      modalLoading: true,
      modalTitle: "",
      modalData: {
        infoDasar: null,
        lokasi: null,
        images: [],
      },
      // inset map option
      insetMapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      insetMapAttribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      insetMapMapOptions: {},
      insetMapCenter: [-6.906722, 107.552806],
      insetMapMarker: latLng(-6.906722, 107.552806),
      insetMapZoom: 15,
      insetMapMinZoom: 9,
      insetMapMaxZoom: 24,
      insetMapAttributionControl: false,
      insetMapZoomControl: false,
      insetMapShowMap: true,
      insetMapBounds: null,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      showCat: true,
      showWS: true,
      enableTooltip: true,
      fillColor: "#e4ce7f",
      // cat_geojson: petaCat,
      // ws_geojson: petaWS
      // ws_geojson: peta_ws
    };
  },
  computed: {
    // optionInfra() {
    //   let selected_infra = {
    //     sungai: false,
    //     penampunganAirHujan: false,
    //     tampungan: false,
    //     mataAir: false,
    //     sumur: false,
    //   };
    //   console.log(" prov ",this.provinsi_id)
      

    //   this.dataValue.forEach((data) => {
    //     selected_infra[data.endpoint] = true;
    //   });

    //   let options = [];
    //   if (selected_infra["sungai"]) {        
    //     options.push({
    //       language: "Intake Sungai",
    //       libs: this.markerSungai,
    //     });
    //   }

    //   if (selected_infra["penampunganAirHujan"]) {
    //     options.push({
    //       language: "Penampungan Air Hujan",
    //       libs: this.markerPenampunganAirHujan,
    //     });
    //   }

    //   if (selected_infra["tampungan"]) {
    //     options.push({
    //       language: "Tampungan",
    //       libs: this.markerTampungan,
    //     });
    //   }

    //   if (selected_infra["mataAir"]) {
    //     options.push({
    //       language: "Mata Air",
    //       libs: this.markerMataAir,
    //     });
    //   }

    //   if (selected_infra["sumur"]) {
    //     options.push({
    //       language: "Sumur",
    //       libs: this.markerSumur,
    //     });
    //   }

    //   return options;
    // },
    optionsGeojsonCat() {
      return {
        onEachFeature: this.onEachFeatureFunctionCat,
      };
    },
    onEachFeatureFunctionCat() {
     
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        if (feature.properties.CAT !== "NON CAT") {
          layer.bindTooltip(`${feature.properties.CAT}`, {
            permanent: true,
            className: "catLabel",
            direction: "center",
          });
        }
    
      };
    },
    styleGeojsonCat() {
      return (feature) => ({
        weight: 1,
        color: "#ffffff",
        opacity: 0.8,
        direction: "center",
        fillColor: this.getColor(feature.properties.CAT),
        fillOpacity: feature.properties.CAT === "NON CAT" ? 0 : 0.7,
      });
    },
    optionsGeojsonWs() {
      return {
        onEachFeature: this.onEachFeatureFunctionWS,
      };
    },
    onEachFeatureFunctionWS() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(`${feature.properties.WS}`, {
          permanent: false,
          sticky: true,
          className: "catLabel",
        });
      };
    },
    styleGeojsonWs() {
      return (feature) => ({
        weight: 1,
        color: "white",
        opacity: 0.8,
        fillColor: this.getColor(feature.properties.WS),
        fillOpacity: 0.7,
      });
    },
    optionsGeojsonHidro() {
      return {
        onEachFeature: this.onEachFeatureFunctionHidro,
      };
    },
    onEachFeatureFunctionHidro() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
          `<div>Sistem Akuifer : ${feature.properties.sys_aq}</div><div>Produktifitas Akuifer : ${feature.properties.prod}</div><div>Keterusan : ${feature.properties.keterusan}</div><div>Debit : ${feature.properties.debit}</div><div>Pulau : ${feature.properties.pulau}</div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    styleGeojsonHidro() {
      return (feature) => ({
        weight: 1,
        color: "white",
        opacity: 0.8,
        fillColor: this.getColor(feature.properties.ket_prod),
        fillOpacity: 0.7,
      });
    },
    optionsGeojsonGeo() {
      return {
        onEachFeature: this.onEachFeatureFunctionGeo,
      };
    },
    onEachFeatureFunctionGeo() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
          `<div> ${feature.properties.B_CLASS_EN}</div><div>${feature.properties.CLASS_LITH}</div><div>Formasi : ${feature.properties.FORMATION}</div><div>Nama : ${feature.properties.NAME}</div><div>Nama Lembar : ${feature.properties.NM_LEMBAR}</div><div>Projek : ${feature.properties.PROJECT}</div><div>Simbol : ${feature.properties.SYMBOL}</div><div>T_Class_En : ${feature.properties.T_CLASS_EN}</div>`,
          { permanent: false, sticky: true }
        );
      };
    },
    styleGeojsonGeo() {
      return (feature) => ({
        weight: 1,
        color: "white",
        opacity: 0.8,
        fillColor: this.getColor(feature.properties.NO_LEBAR),
        fillOpacity: 0.7,
      });
    },
    optionsGeojsonProv() {
      return {
        onEachFeature: this.onEachFeatureFunctionProv,
      };
    },
    onEachFeatureFunctionProv() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.on("mouseover", function () {
          this.setStyle({
            weight: 4,
            color: "#5F9DF7",
          });
        });
        layer.on("mouseout", function () {
          this.setStyle({
            weight: 1,
            color: "#1746A2",
          });
        });
      };
    },
    styleGeojsonProv() {
      return (feature) => ({
        weight: 1,
        color: "#1746A2",
        opacity: 0.8,
        fillColor: "#000000",
        fillOpacity: 0,
      });
    },
  },
  async created() {
    this.getProv();
    this.getCatMap();
    this.getWsMap();
    this.getHidroMap();
    this.getGeoMap();
    this.getBatasProv();
  },
  mounted() {
    this.root = document.documentElement;
    const maps = this.$refs.myMap.mapObject;
    const bigimg = L.control.BigImage({
      position: "topright",
      downloadFileName: "siatab-map-export",
      watermark:
        "SIATAB @ Kementerian Pekerjaan Umum dan Perumahan Rakyat Republik Indonesia",
      printControlClasses: ["btn-info"],
      printControlLable:
        '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAgUlEQVQ4jd3QMQrCUBAE0EXEwjIHSWNjZ2UV7LxU9J4mR1CbZ+EXwaBZkFg4zbLL7MwwEVMCJjX4kwSzb00GAmhRv9xqtClF7NBh7Y4VTtinY6FBXwR6NGMPg7KwKc/bUf67trFMGeKC6mPMJ7fC+bHPyzxGRIdFQuMaEYeM2W9wA/dTddQNyCyHAAAAAElFTkSuQmCC">',
    });
    maps.addControl(bigimg);
  },
  methods: {
    zoomUpdated(zoom) {
      console.log("update:zoom fired ", this.zoom);
      this.root.style.setProperty(
        "--geo-json-label-size",
        this.label_size + (this.zoom - this.init_zoom) * 2 + "px"
      );
    },
    // Starting Searching Bar
    selectedInfra(val) {
      console.log("infa search selected", this.infraSearch);
      this.provSelect(this.provinsi_id);
    },
    delectedInfra() {
      this.infraSearch = null;
    },
    cstmSearch({ nama }) {
      return `${nama}`;
    },

    provSelect(val) {
      this.provinsi_id = val;
      this.provDeselect();

      let selected_infra = {
        sungai: false,
        penampunganAirHujan: false,
        tampungan: false,
        mataAir: false,
        sumur: false,
      };
      this.dataValue.forEach((data) => {
        selected_infra[data.endpoint] = true;
      });

      let markers = [];
      let option_list_infra = [];
      let options_infra = {
        sungai: [],
        penampunganAirHujan: [],
        tampungan: [],
        mataAir: [],
        sumur: [],
      };

      
      this.markerSungai.forEach((data) => {
        if (
          selected_infra["sungai"] == false ||
          data.id_prov !== val.id ||
          (this.infraSearch !== null && this.infraSearch.id != data.id)
        ) {
          data.visible = false;
        } else {
          data.visible = true;
          options_infra.sungai.push(data)

          if (data.position.lat && data.position.lng) {
            markers.push({
              latitude: data.position.lat,
              longitude: data.position.lng,
            });
          }
        }
      });

      this.markerPenampunganAirHujan.forEach((data) => {
        if (
          selected_infra["penampunganAirHujan"] == false ||
          data.id_prov != val.id ||
          (this.infraSearch !== null && this.infraSearch.id != data.id)
        ) {
          data.visible = false;
        } else {
          data.visible = true;
          options_infra.penampunganAirHujan.push(data)

          if (data.position.lat && data.position.lng) {
            markers.push({
              latitude: data.position.lat,
              longitude: data.position.lng,
            });
          }
        }
      });

      this.markerTampungan.forEach((data) => {
        if (
          selected_infra["tampungan"] == false ||
          data.id_prov !== val.id ||
          (this.infraSearch !== null && this.infraSearch.id != data.id)
        ) {
          data.visible = false;
        } else {
          data.visible = true;
          options_infra.tampungan.push(data)

          if (data.position.lat && data.position.lng) {
            markers.push({
              latitude: data.position.lat,
              longitude: data.position.lng,
            });
          }
        }
      });

      this.markerMataAir.forEach((data) => {
        if (
          selected_infra["mataAir"] == false ||
          data.id_prov !== val.id ||
          (this.infraSearch !== null && this.infraSearch.id != data.id)
        ) {
          data.visible = false;
        } else {
          data.visible = true;
          options_infra.mataAir.push(data)

          if (data.position.lat && data.position.lng) {
            markers.push({
              latitude: data.position.lat,
              longitude: data.position.lng,
            });
          }
        }
      });

      this.markerSumur.forEach((data) => {
        if (
          selected_infra["sumur"] == false ||
          data.id_prov !== val.id ||
          (this.infraSearch !== null && this.infraSearch.id != data.id)
        ) {
          data.visible = false;
        } else {
          data.visible = true;
          options_infra.sumur.push(data)

          if (data.position.lat && data.position.lng) {
            markers.push({
              latitude: data.position.lat,
              longitude: data.position.lng,
            });
          }
        }
      });

      if(options_infra.sungai.length > 0){
        option_list_infra.push({
          language: "Intake Sungai",
          libs: options_infra.sungai,
        });
      }

      if(options_infra.sumur.length > 0){
        option_list_infra.push({
          language: "Sumur",
          libs: options_infra.sumur,
        });
      }

      if(options_infra.mataAir.length > 0){
        option_list_infra.push({
          language: "Mata Air",
          libs: options_infra.mataAir,
        });
      }

      if(options_infra.tampungan.length > 0){
        option_list_infra.push({
          language: "Tampungan",
          libs: options_infra.tampungan,
        });
      }

      if(options_infra.penampunganAirHujan.length > 0){
        option_list_infra.push({
          language: "PAH ABSAH",
          libs: options_infra.penampunganAirHujan,
        });
      }

      this.optionInfra=option_list_infra;
      

      if (markers.length > 0) {
        let centerx = geolib.getCenter(markers);

        this.$refs.myMap.mapObject.setView(
          L.latLng(centerx.latitude, centerx.longitude),
          8
        );

      }else if(this.dataValue.length == 0){

        this.$bvToast.toast("Silahkan Pilih Jenis Infrastruktur terlebih dahulu", {
          title: `Warning`,
          variant: "danger",
          solid: true,
        });

      } else {
        this.$bvToast.toast("Data Marker Tidak ada Pada Wilayah ini", {
          title: `Warning`,
          variant: "danger",
          solid: true,
        });
      }
    },
    provDeselect() {
      let selected_infra = {
        sungai: false,
        penampunganAirHujan: false,
        tampungan: false,
        mataAir: false,
        sumur: false,
      };
      this.dataValue.forEach((data) => {
        selected_infra[data.endpoint] = true;
      });

      if (selected_infra["sungai"]) {
        this.markerSungai.forEach((data) => {
          data.visible = true;
        });
      }

      if (selected_infra["penampunganAirHujan"]) {
        this.markerPenampunganAirHujan.forEach((data) => {
          data.visible = true;
        });
      }

      if (selected_infra["tampungan"]) {
        this.markerTampungan.forEach((data) => {
          data.visible = true;
        });
      }

      if (selected_infra["mataAir"]) {
        this.markerMataAir.forEach((data) => {
          data.visible = true;
        });
      }

      if (selected_infra["sumur"]) {
        this.markerSumur.forEach((data) => {
          data.visible = true;
        });
      }
    },
    // Ending Searching Bar
    getImage() {
      this.download_peta = true;
      let map = this.$refs.myMap.mapObject;
      var self = this;
      leafletImage(map, function (err, canvas) {
        console.log(map);
        // now you have canvas
        // example thing to do with that canvas:
        var img = document.createElement("img");
        var dimensions = map.getSize();
        img.width = dimensions.x;
        img.height = dimensions.y;
        img.src = canvas.toDataURL();
        self.download_peta = false;

        saveAs(canvas.toDataURL(), "siatab-map.png");
      });

      
    },
    getProv() {
      // Starting Searching Bar
      axiosIns
        .post("/public/provinsi", {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
          },
          data: {
            requireTotalCount: false,
            skip: 0,
            take: 50,
            select: ["provinsi.nama", "provinsi.id"],
          },
        })
        .then((response) => {
          let temp = [{ id: 0, nama: "Semua Provinsi" }];
          this.optSearch = temp.concat(response.data.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Ending Searching Bar
    mapChange(val) {
      if (val === "topo") {
        this.tileProviders[0].visible = false;
        this.tileProviders[1].visible = true;
      } else {
        this.tileProviders[1].visible = false;
        this.tileProviders[0].visible = true;
      }
    },

    dataDeselect(val) {
      let arr = this.dataLegends;
      let index = arr.findIndex((x) => x.name === val.endpoint);
      this.dataLegends[index].visible = false;

      this.dataValue = this.dataValue.filter((obj) => obj.name !== val.name);

      switch (val.endpoint) {
        case "sungai":
          this.markerSungai.forEach((data) => {
            data.visible = false;
          });
          break;
        case "penampunganAirHujan":
          this.markerPenampunganAirHujan.forEach((data) => {
            data.visible = false;
          });
          break;
        case "tampungan":
          this.markerTampungan.forEach((data) => {
            data.visible = false;
          });
          break;
        case "mataAir":
          this.markerMataAir.forEach((data) => {
            data.visible = false;
          });
          break;
        case "sumur":
          this.markerSumur.forEach((data) => {
            data.visible = false;
          });
          break;
        default:
          console.log("defualt");
          break;
      }

      if (this.dataValue.length > 0) {
        this.peta_legend.legend_card = true;
      } else {
        this.peta_legend.legend_card = false;
      }
    },
    dataSelected(val) {
      const arr = this.dataLegends;
      const index = arr.findIndex((x) => x.name === val.endpoint);
      this.dataLegends[index].visible = true;

      if (this.dataValue.length >= 0) {
        this.peta_legend.legend_card = true;
      } else {
        this.peta_legend.legend_card = false;
      }

      switch (val.endpoint) {
        case "sungai":
          this.getSungaiMarkers(this.markerSungai.length < 1);
          break;
        case "penampunganAirHujan":
          this.getPenampunganAirHujanMarkers(
            this.markerPenampunganAirHujan.length < 1
          );
          break;
        case "tampungan":
          this.getTampunganMarkers(this.markerTampungan.length < 1);
          break;
        case "mataAir":
          this.getMataAirMarkers(this.markerMataAir.length < 1);
          break;
        case "sumur":
          this.getSumurMarkers(this.markerSumur.length < 1);
          break;
        default:
          console.log("defualt");
          break;
      }
    },
    
    getSungaiMarkers(visible = true) {
      axiosIns
        .post("/public/sungai", {
          data: {
            requireTotalCount: true,
            skip: 0,
            take: 1000,
            select: [
              "sungai.id",
              "sungai.nama_objek",
              "sungai.lat",
              "sungai.lng",
              "sungai.status_operasi",
              "sungai.provinsi_id",
            ],
          },
        })
        .then((resp) => {
          const { data } = resp.data.data;
          data.forEach((val) => {
            this.boundsSungaiArr.push([val.lat, val.lng]);
            this.markerSungai.push({
              id: `sungai-${val.id}`,
              position: { lat: val.lat, lng: val.lng },
              tooltip: val.nama_objek,
              active: val.status_operasi === "Operasi",
              draggable: false,
              visible: visible && val.lat !== null,
              id_prov: val.provinsi_id,
            });
          });
          this.setBounds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPenampunganAirHujanMarkers(visible = true) {
      axiosIns
        .post("/public/penampunganAirHujan", {
          data: {
            requireTotalCount: true,
            skip: 0,
            take: 1000,
            select: [
              "pah.id",
              "pah.nama_objek",
              "pah.lat",
              "pah.lng",
              "pah.status_operasi",
              "pah.provinsi_id",
            ],
          },
        })
        .then((resp) => {
          const { data } = resp.data.data;
          data.forEach((val) => {
            this.boundsPenampunganAirHujanArr.push([val.lat, val.lng]);
            this.markerPenampunganAirHujan.push({
              id: `penampunganAirHujan-${val.id}`,
              position: { lat: val.lat, lng: val.lng },
              tooltip: val.nama_objek,
              active: val.status_operasi === "Operasi",
              draggable: false,
              visible: visible && val.lat !== null,
              id_prov: val.provinsi_id,
            });
          });
          this.setBounds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTampunganMarkers(visible = true) {
      axiosIns
        .post("/public/tampungan", {
          data: {
            requireTotalCount: true,
            skip: 0,
            take: 1000,
            select: [
              "tampungan.id",
              "tampungan.nama_objek",
              "tampungan.lat",
              "tampungan.lng",
              "tampungan.status_operasi",
              "tampungan.provinsi_id",
            ],
          },
        })
        .then((resp) => {
          const { data } = resp.data.data;
          data.forEach((val) => {
            this.boundsTampunganArr.push([val.lat, val.lng]);
            this.markerTampungan.push({
              id: `tampungan-${val.id}`,
              position: { lat: val.lat, lng: val.lng },
              tooltip: val.nama_objek,
              active: val.status_operasi === "Operasi",
              draggable: false,
              visible: visible && val.lat !== null,
              id_prov: val.provinsi_id,
            });
          });
          this.setBounds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMataAirMarkers(visible = true) {
      axiosIns
        .post("/public/mataAir", {
          data: {
            requireTotalCount: true,
            select: [
              "mata_air.id",
              "mata_air.nama_objek",
              "mata_air.lat",
              "mata_air.lng",
              "mata_air.status_operasi",
              "mata_air.provinsi_id",
            ],
          },
        })
        .then((resp) => {
          const { data } = resp.data;
          data.forEach((val) => {
            if (val.lat !== "" || val.lng !== "") {
              this.boundsMataAirArr.push([val.lat, val.lng]);
              this.markerMataAir.push({
                id: `mataAir-${val.id}`,
                position: { lat: val.lat, lng: val.lng },
                tooltip: val.nama_objek,
                active: val.status_operasi === "Operasi",
                draggable: false,
                visible: visible && val.lat !== null,
                id_prov: val.provinsi_id,
              });
            }
          });
          this.setBounds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSumurMarkers(visible = true) {
      axiosIns
        .post("/public/sumur", {
          data: {
            requireTotalCount: true,
            skip: 0,
            take: 10000,
            select: [
              "sumur.id",
              "sumur.nama_sumur",
              "sumur.lat",
              "sumur.lng",
              "sumur.status_operasi",
              "sumur.provinsi_id",
            ],
          },
        })
        .then((resp) => {
          const { data } = resp.data.data;
          data.forEach((val) => {
            this.boundsSumurArr.push([val.lat, val.lng]);
            this.markerSumur.push({
              id: `sumur-${val.id}`,
              position: { lat: val.lat, lng: val.lng },
              tooltip: val.nama_sumur,
              active: val.status_operasi === "Operasi",
              draggable: false,
              visible: visible && val.lat !== null,
              id_prov: val.provinsi_id,
            });
          });
          this.setBounds();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // get json file
    getCatMap() {
      axiosIns
        .get("/map/cat")
        .then((response) => {
          this.geojsonCat = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWsMap() {
      axiosIns
        .get("/map/ws")
        .then((response) => {
          this.geojsonWs = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHidroMap() {
      axiosIns
        .get("/map/hidro")
        .then((response) => {
          this.geojsonHidro = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGeoMap() {
      axiosIns
        .get("/map/geo")
        .then((response) => {
          this.geojsonGeo = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBatasProv() {
      axiosIns
        .get("/map/prov")
        .then((response) => {
          this.geojsonProv = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWilMarkers(nil) {
      this.getWilSungaiMarkers(nil);
      this.getWilPenampunganAirHujanMarkers(nil);
      this.getWilTampunganMarkers(nil);
      this.getWilMataAirMarkers(nil);
      this.getWilSumurMarkers(nil);
    },
    setBounds() {
      this.bounds = latLngBounds([
        ...this.boundsSungaiArr,
        ...this.boundsPenampunganAirHujanArr,
        ...this.boundsTampunganArr,
        ...this.boundsMataAirArr,
        ...this.boundsSumurArr,
      ]);
    },
    alert(marker) {
      this.modalLoading = true;
      this.modalData = {
        infoDasar: null,
        lokasi: null,
        images: [],
      };

      const segment = marker.id.split("-")[0];
      const id = marker.id.split("-")[1];

      this.modalTitle = marker.tooltip;
      this.insetMapCenter = [marker.position.lat, marker.position.lng];
      this.insetMapMarker = latLng(marker.position.lat, marker.position.lng);
      this.$refs["modal-scrollable"].show();

      axiosIns.get(`/public/${segment}/${id}/detail`).then((resp) => {
        const { data } = resp;

        switch (segment) {
          case "sungai":
            this.modalData.infoDasar = [
              {
                nama_sungai: data.nama_objek,
                nama_sistem: data.nama_sistem,
                debit: `${data.manfaat_debit || 0} (l/detik)`,
                intake: data.jenis_intake,
              },
            ];
            break;
          case "penampunganAirHujan":
            this.modalData.infoDasar = [
              {
                nama_absah: data.nama_sumber_air,
                sistem_pengambilan_air:
                  data.detail.teknis.sistem_pengambilan_air || "-",
              },
            ];
            break;
          case "tampungan":
            this.modalData.infoDasar = [
              {
                nama_tampungan: data.nama_objek,
                debit: `${data.manfaat_debit || 0} (l/detik)`,
              },
            ];
            break;
          case "mataAir":
            this.modalData.infoDasar = [
              {
                nama_mata_air: data.nama_objek,
                debit: `${data.manfaat_debit || 0} (l/detik)`,
                elevasi: `${data.detail.elevasi || 0} (mdpl)`,
              },
            ];
            break;
          case "sumur":
            this.modalData.infoDasar = [
              {
                nama_sumur: data.nama_objek,
                kedalaman: `${data.detail.teknis.kedalaman_sumur || 0} (m)`,
                pumping_test: `${data.debit_awal || 0} (l/detik)`,
                debit_pompa: `${data.debit_andalan || 0} (l/detik)`,
                idle: `${data.debit_konservasi || 0} (l/detik)`,
              },
            ];
            break;
          default:
            console.log("defualt");
            break;
        }
        this.modalData.lokasi = [
          {
            provinsi: data.provinsi ? data.provinsi.nama : "-",
            kota: data.kota ? data.kota.nama : "-",
            kecamatan: data.kecamatan ? data.kecamatan.nama : "-",
            kelurahan: data.kelurahan ? data.kelurahan.nama : "-",
          },
        ];
        if (data.images.length > 0) {
          const { images } = data;
          images.forEach((d) => {
            this.modalData.images.push({
              img: d.fileUrlPath,
            });
          });
        }

        this.modalLoading = false;
      });
    },
    validateLatLng(lat, lng) {
      const pattern = new RegExp("^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}");
      return pattern.test(lat) && pattern.test(lng);
    },
    getColor() {
      // if (d > 100){
      //   return d = '#800026';
      // } elif (d > 6000) {
      //   return d = '#FD8D3C';
      // }
      const number = ((Math.random() * 0xffffff) << 0).toString(16); // eslint-disable-line no-bitwise
      const color = `#${number}`;
      return color;
    },
  },
};
</script>

<style src="swiper/css/swiper.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.map {
  background: red;
  margin: 0;
  height: 84.4vh;
}
.inset-map {
  height: 16rem;
  width: 100%;
}
.group-1 {
  position: absolute;
  z-index: 500;
  width: 500px;
  top: 15px;
  left: 15px;
  padding: 1px;
}
.filter-button {
  padding-top: 1px;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 12px;
}
.select-data-wil {
  display: none;
  position: absolute;
  z-index: 500;
  width: 250px;
  top: 10px;
  left: 430px;
  font-weight: 400;
}
#legend {
  z-index: 500;
  font-family: Arial, Helvetica, sans-serif;
  background: white;
  position: absolute;
  bottom: 40px;
  left: 15px;
  opacity: 0.8;
  width: 185px;
  height: auto;
  padding: 5px 18px;
  border-radius: 10px;
  text-align: left;
  font-size: 3px;
  border: 1px solid;
}
#legend #col-head {
  border-style: none;
  text-align: center;
  padding: 0px;
  font-size: 15px;
}
#legend #col-body {
  border-style: 1px solid;
  padding: 0px 5px;
  text-align: left;
  font-size: 3em;
}
.catLabel {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
  font-size: var(--geo-json-label-size);
}
</style>
